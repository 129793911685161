import i18n from "i18next";
import { Fragment, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";

import { Spinner } from "../ui";

import { PlayIcon } from "../components/icons/PlayIcon.component";
import { StopIcon } from "../components/icons/StopIcon.component";
import styles from "./HeaderListenLive.module.scss";
import type { Store } from "@inferno/renderer-shared-core";
import { COMPONENT_BREAKPOINTS } from "../ui/constants";

export interface HeaderListenLiveProps {
  store?: Store;
}

const ListenLiveButtonContents = ({
  playback,
  showIconOnly,
  showText,
}: {
  playback: boolean;
  showIconOnly: boolean | null;
  showText: boolean | null;
}) => {
  if (showIconOnly) {
    return playback ? <StopIcon /> : <PlayIcon />;
  }

  const PlayState = (text: boolean | null) => (
    <Fragment>
      <StopIcon />
      {text ? i18n.t("listen_stop") : i18n.t("listen_stop_mob")}
    </Fragment>
  );
  const StopState = (text: boolean | null) => (
    <Fragment>
      <PlayIcon />
      {text ? i18n.t("listen_play") : i18n.t("listen_play_mob")}
    </Fragment>
  );

  return playback ? PlayState(showText) : StopState(showText);
};

export const HeaderListenLive = inject("store")(
  observer(({ store }: HeaderListenLiveProps) => {
    if (!store) {
      return null;
    }

    const { playback, inProgress, togglePlayback } = store.player;
    const [showText, setShowText] = useState<boolean | null>(null);
    const [hideTextOverride, setHideTextOverride] = useState<boolean | null>(null);
    const mounted = showText !== null || hideTextOverride !== null;

    const containerClass = classnames(styles.componentListenLive, {
      [styles.iconOnly]: hideTextOverride,
    });

    const handleWindowResize = () => {
      if (window.innerWidth > COMPONENT_BREAKPOINTS.smallTabletBreakpoint) {
        setShowText(true);
      } else {
        setShowText(false);
      }

      // where header grid alignment starts breaking; so we switch to icon only
      if (window.innerWidth < COMPONENT_BREAKPOINTS.headerGridBreakpoint) {
        setHideTextOverride(true);
      } else {
        setHideTextOverride(false);
      }
    };

    useEffect(() => {
      if (isWindowDefined()) {
        handleWindowResize();
        resizeWatcher.onWidthChange.subscribe(handleWindowResize);
        return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
      }
    }, []);

    return mounted ? (
      <div className={containerClass}>
        {inProgress ? (
          <Spinner visible={true} />
        ) : (
          <button
            onClick={() => togglePlayback(store, { sectionName: "header" })}
            className={styles.toggleBtn}
            aria-label="Play Stream Now"
          >
            <span className={styles.btnContent}>
              <ListenLiveButtonContents playback={playback} showIconOnly={hideTextOverride} showText={showText} />
            </span>
          </button>
        )}
      </div>
    ) : null;
  }),
);
