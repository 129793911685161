import type { Store, LeavingSiteEvent } from "@inferno/renderer-shared-core";
import { inject } from "mobx-react";
import { useCallback, useRef } from "react";
import { ExternalLinkIcon } from "../components/icons/ExternalLinkIcon.component";
import { ClickSection } from "@inferno/renderer-shared-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";

export const LeavingSiteButton = inject("store")(
  ({
    label,
    url,
    context,
    type,
    store,
  }: {
    label: string;
    url: string;
    type: string;
    context?: string | null;
    store?: Store;
  }) => {
    if (!type || !store) {
      return null;
    }

    const contextRef = useRef(context);
    const trackClick = useCallback(
      (trackSectionName: string, trackHref: string | any) => {
        const page = store.page.currentPage;
        const pageName = page ? `${store.microsite ? "microsite_" : ""}${page.name}` : "";

        if (!contextRef.current) {
          if (label) {
            contextRef.current = label;
          } else if (typeof trackHref === "string") {
            contextRef.current = trackHref;
          } else {
            contextRef.current = "";
          }
        }

        let referrer = "";
        if (isWindowDefined()) {
          referrer = window.location.href;
        }

        store.onAnalyticsAction.dispatch({
          sectionName: trackSectionName,
          pageName,
          context: contextRef.current,
          action: "click",
          url: trackHref,
          referrer,
          type,
        } as LeavingSiteEvent);
      },
      [store, label, type],
    );

    return (
      <ClickSection.Consumer>
        {sectionVal => (
          <button
            aria-label={`${label || "menu item"}`}
            className="button-text"
            onClick={() => trackClick(sectionVal, url)}
          >
            <span className="menu-label">
              {label}
              <ExternalLinkIcon />
            </span>
          </button>
        )}
      </ClickSection.Consumer>
    );
  },
);
