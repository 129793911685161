export const ScrollUpArrowIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon scroll_up_arrow_Icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="Layer 2">
      <path
        d="M12 22A10 10 0 102 12a10 10 0 0010 10zM8.31 10.14l3-2.86a.49.49 0 01.15-.1.54.54 0 01.16-.1.94.94 0 01.76 0 1 1 0 01.33.21l3 3a1 1 0 01-1.42 1.42L13 10.41V16a1 1 0 01-2 0v-5.66l-1.31 1.25a1 1 0 01-1.38-1.45z"
        className="primary-path"
      />
    </g>
  </svg>
);

export default ScrollUpArrowIcon;
