import classnames from "classnames";
import i18n from "i18next";
import { inject } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ExpandIcon } from "../components/icons/ExpandIcon.component";
import { Remote } from "../components/remote/Remote.component";
import { resolveMenu } from "../lib/menu";
import { slugify } from "@ihr-radioedit/inferno-core";
import { isWindowDefined, reverseRoute } from "@inferno/renderer-shared-core";
import { getContests } from "@ihr-radioedit/inferno-core";
import { SitesMenu } from "./Navigation.component";
import { MenuProps, NavigationMenuItem } from "./NavigationMenu.component";

export const ContestsNavigationMenu = inject("store")(
  ({ store, mainNavOpen = false, forceOpen = false }: Omit<MenuProps, "menu">) => {
    if (!store) {
      return null;
    }

    const brandIds = useMemo(() => {
      const result: { [key: string]: string } = {};
      store.site.micrositeReferences.forEach(index => (result[`/index${index._id}`] = index.slug));
      return result;
    }, [store.site.micrositeReferences]);

    const [open, setOpen] = useState(forceOpen);
    const mounted = useRef<boolean>(false);

    const closeMenu = useCallback(() => {
      if (mounted.current) {
        setOpen(false);
      }
    }, [mounted, setOpen]);

    const toggleOpen = useCallback(() => {
      if (mounted.current) {
        if (!open) {
          const page = store.page.currentPage;
          if (page) {
            const pageName = page ? `${store.microsite ? "microsite_" : ""}${page.name}` : "";
            let referrer = "";
            if (isWindowDefined()) {
              referrer = window.location.href;
            }

            store.onAnalyticsAction.dispatch({
              sectionName: "parent_nav",
              pageName,
              context: i18n.t("promotions") || "",
              action: "click",
              url: "",
              referrer,
            });
          }
        }

        setOpen(!open);
      }
    }, [mounted, open, setOpen, store]);

    useEffect(() => {
      mounted.current = true;
      if (open && !mainNavOpen) {
        closeMenu();
      }
    }, [open, mainNavOpen, mounted, closeMenu]);

    const toggleClass = useMemo(
      () =>
        classnames("menu-top-label", "menu-toggler", {
          open: open && mainNavOpen,
        }),
      [open, mainNavOpen],
    );

    const { navigation } = store.site.sections ?? {};

    const { slug: accountId } = store.site.index;
    const cacheKey = `contestsloader-${accountId}`;
    const contestRules = (
      <NavigationMenuItem
        menu={{
          type: "link",
          label: i18n.t("contest_rules"),
          href: reverseRoute(store.site, "rules"),
        }}
      />
    );

    return (
      <li className={toggleClass}>
        <button
          aria-label={`Toggle Menu for ${i18n.t("promotions")}`}
          className="button-text"
          onClick={toggleOpen}
          tabIndex={0}
        >
          <span className="menu-label">{i18n.t("promotions")}</span>
          <ExpandIcon />
        </button>
        <ul className="submenu-container" aria-hidden={!open}>
          <Remote
            loader={() => getContests({ accountId }, store.tags.surrogateKeys, store.getSdkOpts())}
            cacheKey={cacheKey}
            fallback={contestRules}
            showLoading={false}
          >
            {({ data }) => {
              if (data?.length) {
                const items = data.slice(0, 5).map((contest, i) => {
                  const menuItem: SitesMenu = {
                    type: "link",
                    label: contest.title.trim(),
                    href: store?.site
                      ? contest.gridRedirect ||
                        reverseRoute(store?.site, "promotion", {
                          slug: `${slugify(contest.title.trim(), 6)}-${contest.appId}`,
                        })
                      : "#",
                    context: `contest_${i}`,
                  };
                  return (
                    <li key={`contest_${i}`}>
                      <NavigationMenuItem
                        menu={menuItem}
                        context={menuItem.label ? `${slugify(menuItem.label)}_${i}` : undefined}
                      />
                    </li>
                  );
                });

                return (
                  <>
                    {items}
                    <li>
                      <NavigationMenuItem
                        menu={{
                          type: "link",
                          label: i18n.t("all_promotions"),
                          href: reverseRoute(store.site, "promotions"),
                        }}
                      />
                    </li>
                  </>
                );
              }
              return null;
            }}
          </Remote>
          {navigation?.national_promotions
            ? navigation.national_promotions.map((item, i) => {
                const itemMenu = resolveMenu(item, store.env.MICROSITE_ROOT, null, store.site, brandIds);
                return (
                  <li key={`np_${i}`}>
                    <NavigationMenuItem
                      menu={itemMenu}
                      context={itemMenu.label ? `${slugify(itemMenu.label)}_${i}` : undefined}
                    />
                  </li>
                );
              })
            : null}
          <li>{contestRules}</li>
        </ul>
      </li>
    );
  },
);
