import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import { Fragment } from "react";

import { SiteThemeProps } from "@inferno/renderer-shared-core";
import { Footer } from "@inferno/renderer-shared-ui";
import { AppTray } from "../../core/chrome/AppTray.component";
import { Header } from "../../core/chrome/Header.component";
import { PushdownAd } from "../../core/chrome/PushdownAd.component";
import { Routes } from "../Routes";
import { TemplateComponent } from "../TemplateComponent.component";

const LeavingSiteLoader = loadable(() => import("../../core/components/leaving-site/LeavingSiteLoader.component"), {
  ssr: false,
});
const LegalNoticeLoader = loadable(() => import("../../core/page-blocks/legalnotice/LegalNoticeLoader.component"), {
  ssr: false,
});
const AuthModal = loadable(() => import("../../core/components/AuthModal.component"), {
  ssr: false,
});
const IHRPlayerBar = loadable(() => import("../../core/chrome/IHRPlayerBar.component"), {
  ssr: false,
});

export const LocalSiteTheme = inject("store")(
  observer(({ store, siteTheme }: SiteThemeProps) => {
    if (!store) {
      return null;
    }

    const { request } = store;
    const inApp = !!request.query?.inapp;

    return (
      <TemplateComponent display="grid">
        <Routes
          siteTheme={siteTheme}
          headerGroup={
            <Fragment>
              <PushdownAd />
              {!inApp ? <Header /> : null}
            </Fragment>
          }
          footerGroup={
            <Fragment>
              <LeavingSiteLoader />
              <Footer />
              <AppTray />
              <LegalNoticeLoader />
              <AuthModal />
            </Fragment>
          }
        />
        {!inApp ? <IHRPlayerBar /> : null}
      </TemplateComponent>
    );
  }),
);

export default LocalSiteTheme;
