import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { Fragment, Component } from "react";
import { Translation } from "react-i18next";
import { ExpandIcon } from "../components/icons/ExpandIcon.component";
import { UserIcon } from "../components/icons/UserIcon.component";
import type { BackendSessionState, Store } from "@inferno/renderer-shared-core";
import { Button, MagicLink } from "../ui";
import { ButtonKind } from "../ui";
import { ResponsiveImage } from "../components/ResponsiveImage.component";
import type { SrcSet } from "@inferno/renderer-shared-core";
import "./UserProfile.style.scss";
import { RESPONSIVE_IMAGE } from "../ui/constants";
import { IHRSessionState } from "@inferno/renderer-shared-core";

interface UserProfileProps {
  store?: Store;
  location: string;
}

interface UserProfileState {
  loggedIn: boolean;
  showProfile: boolean;
}

@inject("store")
@observer
export class UserProfile extends Component<UserProfileProps, UserProfileState> {
  mounted = false;

  constructor(props: UserProfileProps) {
    super(props);

    this.state = {
      loggedIn: false,
      showProfile: false,
    };
  }

  private update(state: BackendSessionState) {
    if (this.mounted) {
      this.setState({
        loggedIn: state && state.authenticated && !!state.profile,
      });
    }
  }

  login = () => {
    this.props.store?.onAuthAction.dispatch({
      action: "pending",
    });
    this.props.store?.session?.currentSession?.login();

    const page = this.props.store?.page.currentPage;
    if (page) {
      const pageName = page ? `${this.props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
      let referrer = "";
      if (typeof window !== "undefined") {
        referrer = window.location.href;
      }

      const sectionName = this.props.location;
      this.props.store?.onAnalyticsAction.dispatch({
        sectionName,
        pageName,
        context: "login_icon",
        action: "click",
        url: "",
        referrer,
      });
    }
  };

  signup = () => {
    this.props.store?.onAuthAction.dispatch({
      action: "pending",
    });
    this.props.store?.session?.currentSession?.signup();
  };

  logout = () => {
    this.props.store?.session?.currentSession?.logout();
  };

  componentDidMount() {
    this.mounted = true;

    if (this.props.store) {
      const { site, session } = this.props.store;
      if (session?.currentSession) {
        this.update(session.currentSession.state);
        session.currentSession.onStatusChanged.subscribe(state => {
          if (this.props.store) {
            this.props.store.onAuthAction.dispatch({
              action: "resolve",
            });
          }

          this.update(state);
        });

        const { sections } = site;
        if (this.mounted && sections && sections.navigation && sections.navigation.user_menu_switch) {
          this.setState({ showProfile: true });
        }
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getProfilePic = () => {
    if (this.props.store) {
      const { session } = this.props.store;
      if (this.state.loggedIn && session?.currentSession) {
        const { profile } = session.currentSession.state as IHRSessionState;
        const imgUrl = `//i.iheart.com/v3/user/${profile?.profileId}/profile`;
        const srcset: SrcSet[] = [
          {
            url: `${imgUrl}?ops=gravity(%22center%22),fit(24,24)`,
            descriptor: "1x",
          },
          {
            url: `${imgUrl}?ops=gravity(%22center%22),fit(40,40)`,
            descriptor: "2x",
          },
        ];

        if (profile) {
          return (
            <Translation>
              {t => (
                <MagicLink
                  to={`${this.props.store?.env.NATIONAL_SITE_URL}/my/settings/`}
                  target="_blank"
                  label={t("settings")}
                >
                  <ResponsiveImage
                    alt={t("profile_alt")}
                    src={`${imgUrl}?ops=gravity(%22center%22),fit(24,24)`}
                    srcset={srcset}
                    initialHeight={RESPONSIVE_IMAGE.userProfileHeight}
                    initialWidth={RESPONSIVE_IMAGE.userProfileWidth}
                    placeholder={`${imgUrl}?ops=gravity(%22center%22),fit(24,24)`}
                    placeholderSrcset={srcset}
                  />
                </MagicLink>
              )}
            </Translation>
          );
        }
        return null;
      }
    }

    return (
      <Translation>
        {t => (
          <Button kind={ButtonKind.CUSTOMIZED} click={() => this.login()} label={t("login")}>
            <UserIcon />
          </Button>
        )}
      </Translation>
    );
  };

  getSettingsButton = () => {
    const session = this.props.store?.session.currentSession;

    if (!session) {
      return null;
    }

    return (
      <Translation>
        {t => (
          <MagicLink
            to={`${this.props.store?.env.NATIONAL_SITE_URL}/my/settings/`}
            className="settings"
            target="_blank"
          >
            {t("settings")}
          </MagicLink>
        )}
      </Translation>
    );
  };

  getUserOptions = () => {
    if (this.state.loggedIn) {
      return (
        <Fragment>
          {this.getSettingsButton()}
          <Translation>
            {t => (
              <Button kind={ButtonKind.CUSTOMIZED} className="logout" click={this.logout}>
                {t("logout")}
              </Button>
            )}
          </Translation>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Translation>
          {t => (
            <Button kind={ButtonKind.CUSTOMIZED} className="login" click={this.login}>
              {t("login")}
            </Button>
          )}
        </Translation>
        <Translation>
          {t => (
            <Button kind={ButtonKind.CUSTOMIZED} className="signup" click={this.signup}>
              {t("signup")}
            </Button>
          )}
        </Translation>
      </Fragment>
    );
  };

  render() {
    if (this.state.showProfile && this.props.store) {
      const { session } = this.props.store;
      let profile = null;
      let username = null;

      if (session.currentSession) {
        profile = (session.currentSession.state as IHRSessionState).profile;
        username = profile ? (profile.name || profile.email || "").split("@")[0] : null;
      }

      const userClass = classnames("user-account", {
        authenticated: !!session.currentSession?.authenticated,
      });

      const ProfilePic = () => this.getProfilePic();
      const UserOptions = () => this.getUserOptions();

      return (
        <section className={userClass}>
          <figure className="user-info">
            <section className="thumb">
              <ProfilePic />
            </section>
            <i className="menu-toggler">
              <ExpandIcon />
            </i>
            {username ? <figcaption className="username">{username}</figcaption> : null}
          </figure>
          <div className="options">
            <UserOptions />
          </div>
        </section>
      );
    }

    return null;
  }
}

export default UserProfile;
