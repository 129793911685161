import type { Store, AuthEvent, ContestEvent, LeavingSiteEvent } from "@inferno/renderer-shared-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { SitesMenu as DataMenu } from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import classnames from "classnames";
import { TFunction } from "i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Translation } from "react-i18next";
import { useHistory } from "react-router";
import { HamburgerIcon } from "../components/icons/HamburgerIcon.component";
import ScrollDownArrowIcon from "../components/icons/ScrollDownArrowIcon.component";
import ScrollUpArrowIcon from "../components/icons/ScrollUpArrowIcon.component";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { reverseRoute, resizeWatcher } from "@inferno/renderer-shared-core";
import { socialConfigAbstract, SocialNetworkData } from "@inferno/renderer-shared-core";
import { bodyScroll } from "../lib/utilities";
import { isWindowDefined, getLiveStationUrl } from "@inferno/renderer-shared-core";
import { CloseButton, MagicLink, SearchBox } from "../ui";
import { ADVERTISING_PHONE_NUMBER } from "../ui/constants";
import { ContestsNavigationMenu } from "./ContestsNavigationMenu.component";
import "./Navigation.style.scss";
import { NavigationMenu } from "./NavigationMenu.component";
import { StationLogo } from "./StationLogo.component";
import { UserProfile } from "./UserProfile.component";

const log = ILog.logger("Navigation.component");

export interface SitesMenu extends DataMenu {
  forceOpen?: boolean;
  href?: string;
  resolved?: boolean;
  context?: string;
  children?: SitesMenu[] | null;
  active?: string;
}

interface NavProps {
  menus?: SitesMenu[] | null;
  sections: Webapi.UserConfigFragment;
  store?: Store;
  social?: SocialNetworkData[];
  open?: boolean;
  t: TFunction;
}

interface NavContentProps extends Omit<NavProps, "t"> {
  closeNav: () => void;
}

const NavContent = inject("store")(({ open, menus, store, social, sections, closeNav }: NavContentProps) => {
  if (!store || !menus) {
    return null;
  }
  const navClass = classnames({
    open,
  });
  const liveUrl = getLiveStationUrl(store.site, store.env);
  const navListRef = useRef<HTMLUListElement>(null);
  const [menuTop, setMenuTop] = useState(0);
  const [overflowNavigation, setoverflowNavigation] = useState(false);
  const [scrollingUp, isScrollingUp] = useState(true);

  const toggleScrollDirection = () => {
    let top = navListRef.current?.scrollHeight;

    if (!scrollingUp) {
      top = 0;
    }

    navListRef.current?.scroll({ behavior: "smooth", top });
    isScrollingUp(!scrollingUp);
  };

  const handleWindowResize = () => {
    const menuPosition = navListRef?.current?.getBoundingClientRect();

    if (!!menuPosition) {
      setMenuTop(Math.floor(menuPosition.top));
    }

    let menuItemsLength = 0;
    const menuHeight = navListRef?.current?.offsetHeight;

    if (!!menuHeight) {
      menuItemsLength = Object.values(navListRef?.current!.childNodes).reduce(
        (total: number, i: any) => total + i.offsetHeight,
        -2,
      );
    }

    if (menuHeight! < menuItemsLength) {
      setoverflowNavigation(true);
    } else {
      setoverflowNavigation(false);
    }
  };

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      const onScroll = () => {
        if (navListRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = navListRef.current;
          if (scrollHeight - scrollTop === clientHeight) {
            isScrollingUp(!scrollingUp);
          } else {
            isScrollingUp(scrollingUp);
          }
        }
      };

      window.addEventListener("scroll", onScroll, true);

      return () => {
        resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
        window.removeEventListener("scroll", onScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <nav
        className={navClass}
        tabIndex={open ? 0 : -1}
        aria-hidden={!open}
        aria-label="Primary Site Navigation"
        id="component-site-nav"
      >
        <header className="navbar-header">
          <StationLogo />
          <CloseButton click={closeNav} label="Close Site Navigation" />
        </header>
        <hr className="divider" />
        <section className="nav-inner-wrapper" ref={navListRef}>
          <ul
            style={{
              maxHeight: `calc(100vh - ${menuTop + 100}px)`,
            }}
            className="menu-container main-nav"
          >
            {liveUrl && sections.partners?.iheartradio_switch ? (
              <li className="menu-top-label">
                <Translation>
                  {t => (
                    <button
                      aria-label={t("listen")}
                      className="button-text"
                      onClick={() =>
                        store.player.togglePlayback(store, {
                          sectionName: "nav_menu_item",
                          batch: closeNav,
                        })
                      }
                      tabIndex={0}
                    >
                      <span className="menu-label">{t("listen")}</span>
                    </button>
                  )}
                </Translation>
              </li>
            ) : null}
            {menus &&
              menus.map((menu, i) => {
                return (
                  <NavigationMenu key={i} mainNavOpen={open} forceOpen={menu.forceOpen} menu={menu} store={store} />
                );
              })}
            {store?.site.sections.navigation?.contest_nav_switch ? (
              <ContestsNavigationMenu mainNavOpen={open} store={store} />
            ) : null}
            {sections.navigation?.contact_menu_switch ? (
              <li className="menu-top-label">
                <Translation>
                  {t => (
                    <MagicLink to={reverseRoute(store.site, "contact") || ""} context="contact">
                      {t("contact")}
                    </MagicLink>
                  )}
                </Translation>
              </li>
            ) : null}
            {sections.partners?.postup_switch ? (
              <li className="menu-top-label">
                <Translation>
                  {t => (
                    <MagicLink to={reverseRoute(store.site, "newsletter") || ""} context="newsletter">
                      {t("newsletter")}
                    </MagicLink>
                  )}
                </Translation>
              </li>
            ) : null}
            {sections.navigation?.advertise_switch ? (
              <li className="menu-top-label">
                <Translation>
                  {t => (
                    <MagicLink to={reverseRoute(store.site, "advertise") || ""} context="advertise">
                      {t("advertise_with_us", {
                        name: store.site.sections.general?.name,
                      })}
                    </MagicLink>
                  )}
                </Translation>
              </li>
            ) : null}
            {sections.navigation?.advertise_switch ? (
              <li className="menu-top-label">
                <a href={`tel:${ADVERTISING_PHONE_NUMBER}`}>{ADVERTISING_PHONE_NUMBER}</a>
              </li>
            ) : null}
            {social ? <SocialIcons networks={social} /> : null}
            {overflowNavigation ? (
              <button className="button-text overflow-indicator" onClick={toggleScrollDirection}>
                {scrollingUp ? (
                  <span className="arrow-icon">
                    <ScrollDownArrowIcon />
                  </span>
                ) : (
                  <span className="arrow-icon">
                    <ScrollUpArrowIcon />
                  </span>
                )}
              </button>
            ) : null}
          </ul>
        </section>
        <footer className="navbar-footer">
          {sections.navigation?.search_switch ? <SearchBox location="nav" scrollIntoView={true} /> : null}
          <UserProfile location="nav" />
        </footer>
      </nav>
      {open ? <div className="scroll-blocker" onClick={closeNav} aria-label="Window Scroll Blocker" /> : null}
    </React.Fragment>
  );
});

export const Navigation = inject("store")(
  observer((props: NavProps) => {
    const history = useHistory();
    const socialIcons = useMemo(
      () => props.social ?? socialConfigAbstract(props.sections.social) ?? [],
      [props.social, props.sections.social],
    );

    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(
      (toggledOpen = !open) => {
        if (toggledOpen) {
          const page = props.store?.page.currentPage;
          if (page) {
            const pageName = page ? `${props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
            let referrer = "";
            if (isWindowDefined()) {
              referrer = window.location.href;
            }

            props.store?.onAnalyticsAction.dispatch({
              sectionName: "nav",
              pageName,
              context: "hamburger_menu",
              action: "click",
              url: "",
              referrer,
            });
          }
        }

        setOpen(toggledOpen);
      },
      [props.store?.microsite, props.store?.page.currentPage, props.store?.onAnalyticsAction, open],
    );

    const closeNav = useCallback(() => toggleOpen(false), [toggleOpen]);

    useEffect(() => {
      history.listen(closeNav);
      props?.store?.onAuthAction?.subscribe((event: AuthEvent) => {
        if (event.action === "pending") {
          closeNav();
        }
      });
      props?.store?.onContestAction?.subscribe((event: ContestEvent) => {
        if (event.action === "contest_open") {
          closeNav();
        }
      });
      props?.store?.onAnalyticsAction?.subscribe((event: LeavingSiteEvent) => {
        const { action, type } = event;
        if (type?.toString() === "link_warning" && action === "click") {
          closeNav();
        }
      });

      if (isWindowDefined()) {
        bodyScroll(!open, "nav");
      }
    }, [
      closeNav,
      history,
      open,
      props?.store?.onAnalyticsAction,
      props?.store?.onAuthAction,
      props?.store?.onContestAction,
    ]);

    const Nav = useMemo(() => {
      if (!isWindowDefined() || typeof process !== "undefined") {
        return (
          <NavContent
            open={open}
            menus={props.menus}
            social={socialIcons}
            closeNav={closeNav}
            sections={props.sections}
          />
        );
      }
      return ReactDOM.createPortal(
        <NavContent
          open={open}
          menus={props.menus}
          social={socialIcons}
          closeNav={closeNav}
          sections={props.sections}
        />,
        document.body,
      );
    }, [closeNav, open, props.menus, props.sections, socialIcons]);

    if (!props.store) {
      log.error("No Store Available!");
      return null;
    }

    return (
      <Fragment>
        <button aria-label="Open Site Navigation" className="nav-toggler" onClick={toggleOpen} tabIndex={0}>
          <HamburgerIcon />
        </button>
        {Nav}
      </Fragment>
    );
  }),
);
