export const PlayIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-play"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="secondary-path" cx="12" cy="12" r="12" fill="none" stroke="none" />
    <path
      tabIndex={-1}
      className="primary-path"
      d="M10 15.0611V8.93886C10 8.58027 10.4111 8.37036 10.6997 8.58902L14.7841 11.6502C15.0203 11.8251 15.0203 12.1749 14.7841 12.3498L10.6997 15.411C10.4111 15.6296 10 15.4197 10 15.0611Z"
      fill="none"
    />
  </svg>
);
