import { inject, observer } from "mobx-react";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";

import { getPushDown } from "@inferno/renderer-shared-core";
import { Ad } from "../page-blocks/Ad.component";
import "./PushdownAd.style.scss";

interface PushdownAdProps {
  store?: Store;
}

@inject("store")
@observer
export class PushdownAd extends Component<PushdownAdProps> {
  render() {
    const { store } = this.props;
    if (!store?.page.currentPage) {
      return null;
    }

    const pushdown = getPushDown(store.page.currentPage);
    if (!pushdown) {
      return null;
    }

    return (
      <div className="component-pushdown">
        <Ad block={pushdown} isPrimary={pushdown?.id === store.primaryBlockId} />
      </div>
    );
  }
}
