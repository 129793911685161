import { useEffect, useMemo } from "react";
import { Translation } from "react-i18next";
import { inject, observer } from "mobx-react";

import { ClickSection } from "@inferno/renderer-shared-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { HeaderUser } from "./HeaderUser.component";
import { Navigation } from "./Navigation.component";
import { StationLogo } from "./StationLogo.component";
import { HeaderListenLive } from "./HeaderListenLive.component";
import { HeaderSearch } from "./HeaderSearch.component";

import "./Header.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

export interface HeaderProps {
  /**
   * SiteConfig Object, see type SiteBrand
   *
   * @default SiteBrand Object, not null
   */
  open?: boolean;
  store?: Store;
}

export const Header = inject("store")(
  observer(({ store, open }: HeaderProps) => {
    if (!store) {
      return null;
    }
    const { site, device, page } = store;
    const design = site.config.sections?.design;
    const hideListenButton = page.currentPage?.tags.filter(
      tag => tag.id.trim() === "display-hints/hide-listen-now",
    ).length;
    const headerCta: JSX.Element | null = useMemo(() => {
      if (device.enableHeaderSearch === null) return null;
      const playerBarDisabled =
        hideListenButton || !design || !design.ihr_embed_player_bar_switch || !design.ihr_embed_player_bar_stream_theme;
      return device.enableHeaderSearch || playerBarDisabled ? <HeaderSearch location="header" /> : <HeaderListenLive />;
    }, [device.enableHeaderSearch, design, hideListenButton]);

    useEffect(() => {
      if (isWindowDefined()) {
        store.device.refresh(navigator);
      }
    }, [store.device]);

    return (
      <header className="component-site-header">
        <section className="main-header-items">
          <ClickSection.Provider value="nav">
            <Translation>
              {t => <Navigation menus={site.sections.navigation?.menu} open={open} sections={site.sections} t={t} />}
            </Translation>
          </ClickSection.Provider>
          <ClickSection.Provider value="header">
            <StationLogo />
            <section className="listen-user-container">
              {headerCta}
              <HeaderUser showMenu={false} />
            </section>
          </ClickSection.Provider>
        </section>
      </header>
    );
  }),
);
