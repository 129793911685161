import classnames from "classnames";
import { inject } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { ExpandIcon } from "../components/icons/ExpandIcon.component";
import { resolveMenu } from "../lib/menu";
import { slugify } from "@ihr-radioedit/inferno-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { MagicLink } from "../ui";
import { LeavingSiteButton } from "../ui/LeavingSiteButton";
import { SitesMenu } from "./Navigation.component";

export interface MenuProps {
  menu: SitesMenu;
  mainNavOpen?: boolean;
  forceOpen?: boolean;
  store?: Store;
}

export const NavigationMenuItem = ({ menu, context }: { menu: SitesMenu; context?: string }) => {
  return menu.type && menu.type === "link_warning" ? (
    <LeavingSiteButton url={menu.href ?? ""} label={menu.label ?? ""} type={menu.type ?? ""} />
  ) : (
    <MagicLink
      to={menu.href || ""}
      label={menu.label || ""}
      target={menu.target || ""}
      context={context || menu.context}
    >
      {menu.label}
    </MagicLink>
  );
};

export const NavigationMenu = inject("store")(({ menu, store, mainNavOpen = false, forceOpen = false }: MenuProps) => {
  if (!store) {
    return null;
  }

  const brandIds = useMemo(() => {
    const result: { [key: string]: string } = {};
    store.site.micrositeReferences.forEach(index => (result[`/index${index._id}`] = index.slug));
    return result;
  }, [store.site.micrositeReferences]);

  const resolvedMenu = useMemo(() => {
    return resolveMenu(menu, store.env.MICROSITE_ROOT, null, store.site, brandIds);
  }, [menu, store, brandIds]);

  const [open, setOpen] = useState(forceOpen);
  const mounted = useRef<boolean>(false);

  const closeMenu = useCallback(() => {
    if (mounted.current) {
      setOpen(false);
    }
  }, [mounted, setOpen]);

  const toggleOpen = useCallback(() => {
    if (mounted.current) {
      if (!open) {
        const page = store.page.currentPage;
        if (page) {
          const pageName = page ? `${store.microsite ? "microsite_" : ""}${page.name}` : "";
          let referrer = "";
          if (isWindowDefined()) {
            referrer = window.location.href;
          }

          store.onAnalyticsAction.dispatch({
            sectionName: "parent_nav",
            pageName,
            context: menu.label || "",
            action: "click",
            url: "",
            referrer,
          });
        }
      }

      setOpen(!open);
    }
  }, [menu, mounted, open, setOpen, store]);

  useEffect(() => {
    mounted.current = true;
    if (open && !mainNavOpen) {
      closeMenu();
    }
  }, [open, mainNavOpen, mounted, closeMenu]);

  const toggleClass = useMemo(
    () =>
      classnames("menu-top-label", "menu-toggler", {
        open: open && mainNavOpen,
      }),
    [open, mainNavOpen],
  );

  if (resolvedMenu.children?.length) {
    return (
      <li data-testid="menu-top-label" className={toggleClass}>
        <button
          aria-label={`Toggle Menu for ${menu.label || "menu item"}`}
          className="button-text"
          onClick={toggleOpen}
          tabIndex={0}
        >
          <span className="menu-label">{menu.label}</span>
          <ExpandIcon />
        </button>
        <ul className="submenu-container" aria-hidden={!open}>
          {!menu.children
            ? null
            : menu.children.map((menuItem: SitesMenu, index: number) =>
                menuItem && menuItem.href && menuItem.label ? (
                  <li key={index}>
                    <NavigationMenuItem
                      menu={menuItem}
                      context={menu.label ? `${slugify(menu.label)}_${index}` : undefined}
                    />
                  </li>
                ) : null,
              )}
        </ul>
      </li>
    );
  }

  return (
    <li className="menu-top-label">
      <NavigationMenuItem menu={resolvedMenu} />
    </li>
  );
});
