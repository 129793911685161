export const HamburgerIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-hamburger"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15" fill="none" stroke="none" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      tabIndex={-1}
      className="primary-path"
      fill="none"
      d="M7 22h18v-2H7v2zm0-5h18v-2H7v2zm0-7v2h18v-2H7z"
    />
  </svg>
);
