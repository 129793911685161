export const StopIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-stop"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="none" stroke="none" />
    <path
      tabIndex={-1}
      className="primary-path"
      d="m10 9h4c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1z"
      fill="none"
    />
  </svg>
);
